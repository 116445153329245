.person-page {
  color: white;
}

.profile{
  padding-bottom: 2rem !important;
  font-weight: bold;
  font-size:50px;
  margin-top: -3rem !important;
}

.form-container {
  max-height: 58vh;
  max-width: 45vw;
  overflow-y: auto;
}

@media only screen and (max-width: 600px) {
  .form-container {
    max-width: 90vw;
  }
}

@media only screen and (max-width: 900px) {
  .form-container {
    max-width: 70vw;
  }
}
