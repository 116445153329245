.link-person {
  text-decoration: none;
}

.done-text {
  color: forestgreen;
  font-weight: bold;
}

.progress-text {
  font-weight: bold;
}
