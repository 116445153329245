.main-scoring-div {
  height: 100%;
}

.error {
  color: red;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 0.25rem;
  font-weight: bold;
}

input:focus,
select:focus {
  border-color: #7ba662;
}

form label {
  font-size: 1rem;
  font-weight: bold;
  display: block;
  text-align: left;
  margin: 1rem 0 0.2rem;
}

input,
select {
  width: 100%;
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  color: black;
  border: 2px solid #313f2e;
  background-color: #ffffff;
  border-radius: 10px;
  outline: none;
}

input::placeholder,
select::placeholder {
  color: #a0aec0;
}

input.input-error,
select.input-error {
  border-color: #fc8181;
}

.buttons {
  margin-top: 0.5rem !important;
}

.button {
  width: 100%
}


