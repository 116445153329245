.layout-div {
  background-color: #e2e2e2;
  height: 100vh;
}

.background-image {
  background-image: url('../../assets/leaf.jpg');
  background-size: cover;
  width: 100%;
  height: calc(100vh - 48px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
